import React from 'react';
import { useCommonTrans } from '../utils/hooks';
import { ContactButton } from './buttons';
import { JumpBoxLink } from './jumpboxlink';
import { PageSection } from './layout';
import { PageText } from './typography';
import { JumpBoxLinkList } from './jumpboxlinkList';

// items expects an array of shape:
// [{title: 'Item 1', desc: 'Description text'}]
export function ServiceJumpTable({ items = [], type = 'service', paddingTop = true, paddingBottom = true }) {
    const t = useCommonTrans();
    return (
        <PageSection size="big" className="py-4" paddingTop={paddingTop} paddingBottom={paddingBottom} isDark>
            <PageText
                className="max-w-4xl bg-gradient-to-r from-primary to-light-grey text-clean-white py-4 px-4 flex items-center justify-between"
                type="smallCaps"
            >
                {type === 'service' && t.jumptable.services}
                {type === 'platform' && t.jumptable.use_cases}
                {type === 'platform-content' && t.jumptable.content}
                {type === 'compliance' && t.jumptable.compliance}
            </PageText>
            {type === 'platform-content' && (
                <ul className="max-w-4xl">
                    {items.map(item => (
                        <JumpBoxLink
                            title={item.title}
                            desc={item.desc}
                            key={item.title}
                        />
                    ))}
                </ul>
            )}
            {type === 'platform' && (
                <ul className="grid sm:grid-cols-2 gap-2 max-w-4xl">
                    {items.map(item => (
                        <JumpBoxLink
                            title={item.title}
                            desc={item.desc}
                            key={item.title}
                        />
                    ))}
                </ul>
            )}
            {type === 'compliance' && (
                <ul className="grid sm:grid-cols-2 gap-2 max-w-4xl">
                    {items.map(item => (
                        <JumpBoxLinkList
                            title={item.title}
                            desc={item.desc}
                            key={item.title}
                        />
                    ))}
                </ul>
            )}
            {type === 'service' && (
                <div>
                    <ul className="grid sm:grid-cols-2 gap-2 max-w-4xl">
                        {items.map(item => (
                            <JumpBoxLink
                                title={item.title}
                                desc={item.desc}
                                key={item.title}
                            />
                        ))}
                    </ul>
                    <div className="mt-8 text-center">
                        <ContactButton isBig requestDemo={false} />
                    </div>
                </div>
            )}
        </PageSection>
    );
}
