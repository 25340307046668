import React from 'react';
import { ContactButton, EmailButton } from './buttons';
import { PageSection } from './layout';

export function ContactSection({
    requestDemo = true,
    isDark = true,
    children,
}) {
    return (
        <PageSection
            isDark={isDark}
            size="full"
            className={`text-center ${isDark ? 'mt-16' : ''}`}
        >
            {children}
            <ContactButton isBig requestDemo={requestDemo} />
        </PageSection>
    );
}

export function ApplicationSection({ children, isDark }) {
    return (
        <PageSection
            isDark={isDark}
            size="full"
            className={`text-center ${isDark ? 'mt-16' : ''}`}
        >
            <EmailButton email="jobs@condignum.com" subject="Bewerbung" />
            {children}
        </PageSection>
    );
}
