import React from 'react';

import Layout, {
    bgImageDefault,
    PageHeaderB,
    PageSection,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { graphql } from 'gatsby';
import { ServiceJumpTable } from '../components/servicejumptable';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { makeSectionId } from '../components/jumpboxlink';
import Seo from '../components/seo';

function SecureArchitecture({ data, location }) {
    const { t } = useTranslation();
    const text = [
        // 0
        {
            title: t(
                'bedrohungsmodellierung_title',
                'Bedrohungsmodellierung / Security Requirements Engineering'
            ),
            desc: t(
                'bedrohungsmodellierung_desc',
                'Schon vor der Umsetzung wissen worauf es ankommt'
            ),
        },
        // 1
        {
            title: t(
                'security_architect_id',
                'Security Architect as a Service'
            ),
            desc: t(
                'security_architect_desc',
                'Kann das bitte mal schnell jemand prüfen?'
            ),
        },
        // 2
        {
            title: t(
                'pam_implementation_title',
                'PAM Einführungskonzeption'
            ),
            desc: t(
                'pam_implementation_desc',
                'Privilegierte Zugriffe absichern'
            ),
        },
        // 3
        {
            title: t(
                'ad_tiering_concept_title',
                'AD Tiering Modellkonzeption'
            ),
            desc: t(
                'ad_tiering_concept_desc',
                'Absicherung des Active Directory'
            ),
        },
        // 4
        {
            title: t(
                'secure_administration_title',
                'Konzeption sicherer Netzwerk- und Systemadministration'
            ),
            desc: t(
                'secure_administration_desc',
                'Sichere Gestaltung der Administrationswege in Ihrem Netzwerk'
            ),
        },
        // 5
        {
            title: t(
                'secure_netarch_title',
                'Netzwerk und Infrastruktur Security'
            ),
            desc: t(
                'secure_netarch_desc',
                'Absicherung der grundlegenden Infrastruktur'
            ),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.services}
            headerContent={
                <PageHeaderB
                    title={t('title', 'Secure Architecture')}
                    text={
                        <Trans i18nKey="subtitle">
                            Angreifern genügt ein einziges Schlupfloch für einen
                            erfolgreichen Angriff. Nur durch ganzheitliche
                            Betrachtung des “Big&nbsp;Pictures” können alle
                            Flanken abgesichert werden.
                        </Trans>
                    }
                />
            }
        >
            <Seo title={t('title', 'Secure Architecture')} />

            <ServiceJumpTable items={text} />

            <PageSection size="big" id={makeSectionId(text[0].title)}>
                <PageTitle>{text[0].title}</PageTitle>
                <PageSubtitle>{text[0].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="bedrohungsmodellierung_p1">
                        Bei der Konzeption von IT - Vorhaben - egal ob
                        Softwareentwicklung, Systemeinführung oder Aufbau eines
                        neuen Netzwerks - stellt sich früher oder später die
                        Frage: “Ist das denn auch sicher?”
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="bedrohungsmodellierung_p2">
                        Unsere condignum Security Spezialisten helfen Ihnen
                        diese Frage zu beantworten. Im Zuge einer
                        Bedrohungsmodellierung wird Ihre Konzeption und
                        Architektur analysiert, mögliche Bedrohungen und
                        Angriffsvektoren identifiziert und maßgeschneiderte
                        Sicherheitsmaßnahmen zur Absicherung Ihres Vorhabens
                        ausgearbeitet. So wird Ihr Vorhaben von Beginn an
                        zielgerichtet und angemessen abgesichert und aufwändige
                        Nacharbeiten bleiben erspart.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(text[1].title)} isDark>
                <PageTitle>{text[1].title}</PageTitle>
                <PageSubtitle>{text[1].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="security_architect_p1">
                        Komplexe Problemstellungen bedürfen erfahrener
                        Spezialisten. Doch diese sind rar und nicht immer
                        greifbar.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="security_architect_p2">
                        Unser “Security Architect as a Service” Modell
                        ermöglicht es Ihnen schnell und unkompliziert erfahrene
                        Security Spezialisten für Ihre IT-Vorhaben zu Rate zu
                        ziehen. Egal ob Entwicklung einer neuen Software,
                        Einführung eines neuen Systems oder Aufbau eines neuen
                        Netzwerks - in gemeinsamen Workshops wird Ihr Vorhaben
                        und dessen Auswirkungen auf Ihre IT-Landschaft
                        analysiert und angemessene Sicherheitsmaßnahmen für eine
                        sichere Umsetzung identifiziert. Auch während der
                        Umsetzung stehen unsere Security Architekten als
                        Sparring-Partner zur Verfügung und begleiten sowohl bei
                        der Weiterentwicklung der Konzepte, als auch Prüfung der
                        korrekten Umsetzung.
                    </Trans>
                </PageText>
            </PageSection>

            {/* PAM Einführungskonzeption */}
            <PageSection size="big" id={makeSectionId(text[2].title)}>
                <PageTitle>{text[2].title}</PageTitle>
                <PageSubtitle>{text[2].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="pam_implementation_p1">
                    Missbrauch unzureichend abgesicherter privilegierter Zugriffe gehört zu den Top Ausbreitungsvektoren interner Angreifer.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="pam_implementation_p2">
                    Durch Einführung eines <em>Privileged Access Management Systems</em> können diese Zugriffe vielschichtig abgesichert und das Risiko einer Privilegienerhöhung für Angreifer deutlich gesenkt werden.Doch die Einführung eines PAM muss wohl überlegt und geplant sein.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="pam_implementation_p3">
                    Condignum hilft Ihnen bei der Erstellung eines PAM Einführungskonzeptes um eine effektive und reibungslose Einführung zu ermöglichen.
                    </Trans>
                </PageText>
            </PageSection>

            {/* AD Tiering */}
            <PageSection size="big" id={makeSectionId(text[3].title)} isDark>
                <PageTitle>{text[3].title}</PageTitle>
                <PageSubtitle>{text[3].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="ad_tiering_concept_p1">
                    Das Active Directory stellt das Herz der Infrastruktur der meisten Organisationen 
                    dar. Auch für Angreifer, welche bereits Zugriff auf ein Netzwerk besitzen, ist 
                    das AD eines der ersten Angriffsziele, da es ihnen zu weitreichenden 
                    Rechteausweitungen dienen kann.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="ad_tiering_concept_p2">
                    Viel zu häufig sind AD-Strukturen nicht ausreichend sicher designed und 
                    Angreifer haben ein leichtes Spiel.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="ad_tiering_concept_p3">
                    Condignum hilft Ihnen Ihr AD sicher zu designen und vor Angreifern abzusichern.
                    </Trans>
                </PageText>
            </PageSection>

            {/* Administrationskonzept */}
            <PageSection size="big" id={makeSectionId(text[4].title)}>
                <PageTitle>{text[4].title}</PageTitle>
                <PageSubtitle>{text[4].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="secure_administration_p1">
                    Administrative Interfaces und Schnittstellen öffnen bei mangelnder Absicherung 
                    Tür und Tor für Angreifer und ermöglichen es ihnen sich im Netzwerk auszubreiten 
                    und ihre Rechte zu erhöhen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="secure_administration_p2">
                    Durch geschickte Gestaltung der Administrationswege und Absicherung der 
                    administrativen Interfaces können Sie dies verhindern und condignum hilft Ihnen 
                    dabei.
                    </Trans>
                </PageText>
            </PageSection>

            {/* Netzwerkarchitektur */}
            <PageSection size="big" id={makeSectionId(text[5].title)} isDark>
                <PageTitle>{text[5].title}</PageTitle>
                <PageSubtitle>{text[5].desc}</PageSubtitle>
                <PageText>
                    <Trans i18nKey="secure_netarch_p1">
                    Die angemessene Gestaltung eines sicheren IT-Netzwerks hängt sowohl von internen 
                    als auch externen Faktoren ab.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="secure_netarch_p2">
                    Wir unterstützen Sie bei der Planung und Konzeption eines sicheren Netzwerks, 
                    das sowohl formal allen bestehenden Anforderungen entspricht als auch realen 
                    Schutz vor Angreifern bietet. Darüber hinaus begleiten wir Sie bei der 
                    Absicherung Ihrer Netzwerkkomponenten und -Dienste, sodass einem sicheren 
                    Netzwerkbetrieb nichts mehr im Wege steht und Ihre Geschäftsprozesse effizient 
                    und sicher abgewickelt werden können.
                    </Trans>
                </PageText>
            </PageSection>

            <ContactSection requestDemo={false} isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["secure-architecture"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecureArchitecture;
