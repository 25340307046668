import { Link } from 'gatsby';
import React from 'react';
import { BoxLight } from './box';
import { Tag, TagList } from './tags';
import { PageText } from './typography';
import { Ul } from './layout';

export function JumpBoxLinkList({ title, desc, labels }) {
    const descArray = Array.isArray(desc) ? desc : [desc];
    return (
        <BoxLight key={title} as="li" hasHoverEffect className="relative">
            <Link to={'#' + makeSectionId(title)} className="p-4 block">
                <PageText type="highlighted" className="mb-0">
                    {title}
                </PageText>
                <Ul>
                    {descArray.map((descItem, index) => (
                        <li key={index}>
                            <PageText className="mb-12">{descItem}</PageText>
                        </li>
                    ))}
                </Ul>
                {labels && (
                    <TagList className="absolute bottom-4">
                        {labels.map(label => (
                            <Tag key={label}>{label}</Tag>
                        ))}
                    </TagList>
                )}
            </Link>
        </BoxLight>
    );
}

export function makeSectionId(id) {
    return id.replaceAll(' ', '-');
}
