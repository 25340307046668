import { Link } from 'gatsby';
import React from 'react';
import { BoxLight } from './box';
import { Tag, TagList } from './tags';
import { PageText } from './typography';

export function JumpBoxLink({ title, desc, labels }) {
    return (
        <BoxLight key={title} as="li" hasHoverEffect className="relative">
            <Link to={'#' + makeSectionId(title)} className="p-4 block">
                <PageText type="highlighted" className="mb-0">
                    {title}
                </PageText>
                {desc && <PageText className="mb-12">{desc}</PageText>}
                {labels && (
                    <TagList className="absolute bottom-4">
                        {labels.map(label => (
                            <Tag key={label}>{label}</Tag>
                        ))}
                    </TagList>
                )}
            </Link>
        </BoxLight>
    );
}

export function makeSectionId(id) {
    return id.replaceAll(' ', '-');
}
