import React from 'react';

export function TagList({ children, as = 'ul', className = '' }) {
    return React.createElement(
        as,
        {
            className: `flex gap-2 items-center flex-wrap ${className}`,
        },
        children
    );
}

export function Tag({ children, as = 'li', className = '' }) {
    return React.createElement(
        as,
        {
            className: `px-2 border border-light-grey text-gray-500 text-xs font-normal inline-block whitespace-nowrap ${className}`,
            style: { lineHeight: 2 },
        },
        children
    );
}
