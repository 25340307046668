import React from 'react';
import PropTypes from 'prop-types';

export function Box({ children, as = 'div', className }) {
    return React.createElement(
        as,
        { className: `bg-light-grey py-6 px-4 ${className}` },
        children
    );
}

export function BoxLight({
    children,
    as = 'div',
    hasHoverEffect = false,
    className,
}) {
    const hoverEffect =
        'border-2 border-blue-0 hover:border-blue-60 cursor-pointer';
    return React.createElement(
        as,
        {
            className: `bg-clean-white shadow-sm relative ${
                hasHoverEffect && hoverEffect
            } ${className}`,
        },
        children
    );
}

Box.propTypes = {
    children: PropTypes.node.isRequired,
    as: PropTypes.string,
    className: PropTypes.string,
};

BoxLight.propTypes = {
    children: PropTypes.node.isRequired,
    as: PropTypes.string,
    hasHoverEffect: PropTypes.bool,
    className: PropTypes.string,
};
